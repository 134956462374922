import './index.css';

import Splashscreen from '@components/Display/Splashscreen';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import registerServiceWorker from './registerServiceWorker';

const App = React.lazy(() => import('./App'));
const swHandler = {}; // create reference to be called by service worker register, and to be filled in by app
ReactDOM.render(
	<React.Suspense fallback={<Splashscreen />}>
		<App swHandler={swHandler} />
	</React.Suspense>,
	document.getElementById('root') as HTMLElement
);
registerServiceWorker(swHandler);
